import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Service from './../../extra/getAPI';
import { message } from 'antd';
import { AuthContext } from './../../context/AuthProvider';

const theme = createTheme();

export default function SignIn() {
  const history = useHistory();
  const [err, setErr] = React.useState('');
  const [errUser, seterrUser] = React.useState(false);
  const [errPass, seterrPass] = React.useState(false);
  const { getProfile } = useContext(AuthContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('phone') && data.get('password')) {
      let dataInput = {
        phone: data.get('phone'),
        password: data.get('password'),
      };
      Service.apicall('POST', `/auth/login`, dataInput).then((res) => {
        // console.log(res);
        let data = res.data
        if (data.code === 0) {
          success();
          localStorage.setItem('login', "done");
          localStorage.setItem('token', data.data.token);
          history.push('/');
          setTimeout(() => {
            getProfile();
          }, 200);
        } else {
          setErr('Tên đăng nhập hoặc mật khẩu không đúng.');
        }
      });
    } else {
      setErr('Vui lòng nhập đầy đủ thông tin.')
      if (!data.get('phone')) {
        seterrUser(true);
      }
      if (!data.get('password')) {
        seterrPass(true);
      }
    }
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case 'phone':
        if (value) {
          seterrUser(false);
        } else {
          seterrUser(true);
        }
        break;
      case 'password':
        if (value) {
          seterrPass(false);
        } else {
          seterrPass(true);
        }
        break;
      default:
        break;
    }
  };
  const success = () => {
    message.success('Đăng nhập thành công.');
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <ThemeProvider theme={theme} >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#06b3ff' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Đăng Nhập
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                error={errUser}
                onChange={(e) => onChange(e)}
                required
                fullWidth
                id="number"
                label="Số điện thoại"
                name="phone"
              // autoComplete={false}
              />
              <TextField
                margin="normal"
                error={errPass}
                required
                fullWidth
                onChange={(e) => onChange(e)}
                name="password"
                label="Mật khẩu"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <div style={{ color: 'red', fontSize: 12, display: err ? 'block' : 'none' }}>
                {err}
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ backgroundColor: '#06b3ff' }}
                sx={{ mt: 3, mb: 2 }}
              >
                Đăng nhập
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}